<template>
  <div>
    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn
          @click="$router.push('/configuracoes/anamnese/novo')"
          bottom
          large
          color="primary"
          >Novo Modelo</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="anamneses.data"
      :loading="loading"
      :items-per-page="-1"
      @click:row="openAnamneseForm($event.id)"
      :server-items-length="anamneses.total"
      hide-default-footer
      disable-sort
    >
      <template v-slot:[`item.action`]="{ item }">
        <app-delete-btn @click="deleteItem(item.id)" type="icon" />
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="anamneses.current_page"
        :length="anamneses.last_page"
        @input="select($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      anamneses: {},
      loading: false,
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "", align: "end", value: "action" },
      ],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    openAnamneseForm(anamnese_id) {
      this.$router.push(`/configuracoes/anamnese/${anamnese_id}`);
    },

    select(page = 1) {
      this.loading = true;
      this.$http.index("anamnese/anamnese", { page: page }).then((response) => {
        this.anamneses = response.anamneses;
        this.loading = false;
      });
    },

    deleteItem(anamnese_id) {
      this.$loading.start();
      this.$http
        .destroy("anamnese/anamnese", anamnese_id)
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>